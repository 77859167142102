import React from "react"
import tw from "tailwind.macro"

const StyledDiv = tw.div`p-5 bg-white shadow-md rounded-lg`

const Panel = ({ children, ...props }) => {
  return <StyledDiv {...props}>{children}</StyledDiv>
}

export default Panel
