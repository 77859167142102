import React from 'react';
import tw from 'tailwind.macro';
import SafeLink from './safeLink';

const FeaturedTopics = ({ currentTopic }) => {
  return (
    <>
      <SafeLink
        to="/blog/topic/covid-19/"
        css={tw`mr-2 p-2 mb-2 md:mb-0 rounded bg-white shadow inline-block`}
      >
        COVID-19
      </SafeLink>
      <SafeLink
        to="/blog/topic/privilege/"
        css={tw`mr-2 p-2 mb-2 md:mb-0 rounded bg-white shadow inline-block`}
      >
        Privilege
      </SafeLink>
      <SafeLink
        to="/blog/topic/the-constitution/"
        css={tw`mr-2 p-2 mb-2 md:mb-0 rounded bg-white shadow inline-block`}
      >
        The Constitution
      </SafeLink>
      <SafeLink
        to="/blog/topic/corruption/"
        css={tw`mr-2 p-2 mb-2 md:mb-0 rounded bg-white shadow inline-block`}
      >
        Corruption
      </SafeLink>
      <SafeLink
        to="/blog/topic/jobs/"
        css={tw`mr-2 p-2 mb-2 md:mb-0 rounded bg-white shadow inline-block`}
      >
        Jobs
      </SafeLink>
      <SafeLink
        to="/blog/topic/the-president/"
        css={tw`mr-2 p-2 mb-2 md:mb-0 rounded bg-white shadow inline-block`}
      >
        The President
      </SafeLink>
    </>
  );
};

export default FeaturedTopics;
