import { graphql } from 'gatsby';
import React from 'react';
import tw from 'tailwind.macro';
import Button from '../components/button';
import FeaturedTopics from '../components/featuredTopics';
import Layout from '../components/layout';
import LeadText from '../components/leadText';
import Masthead from '../components/masthead';
import Panel from '../components/panel';
import SafeLink from '../components/safeLink';
import Section from '../components/section';
import SEO from '../components/seo';

const PostIndex = ({
  location,
  pageContext: { currentPage, numPages },
  data,
}) => {
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1 ? '/blog/' : '/blog/' + (currentPage - 1).toString();
  const nextPage = '/blog/' + (currentPage + 1).toString() + '/';
  const pageTitle = 'Blog/Events: Beyond Just Us';

  console.log(currentPage);

  return (
    <Layout location={location} parentTitle="Ideas" pageTitle={pageTitle}>
      <SEO title={pageTitle} />
      <Masthead
        pageTitle={pageTitle}
        backgroundImage={data.hero.childImageSharp.fluid}
        styling={[tw`bg-maroon`, `min-height:20vh;`]}
      />
      <Section isVeryThin>
        <strong css={tw`mr-2`}>Featured topics: </strong>
        <FeaturedTopics />
      </Section>
      <Section isShaded>
        {data.allMarkdownRemark.edges.map(({ node }) => {
          return (
            <Panel
              key={node.frontmatter.title}
              css={tw`mb-5 p-10 rounded overflow-hidden`}
            >
              <SafeLink to={`${node.frontmatter.permalink}`}>
                <h2 css={tw`my-0`}>{node.frontmatter.title}</h2>
              </SafeLink>
              <p css={tw`text-gray-600`}>
                {node.frontmatter.date} &middot; By {node.frontmatter.author}
              </p>
              <LeadText>{node.excerpt}</LeadText>
              <SafeLink
                to={node.frontmatter.permalink}
                css={tw`text-maroon hover:underline`}
              >
                Read more...
              </SafeLink>
            </Panel>
          );
        })}

        <div css={tw`mt-10 flex justify-between`}>
          {!isFirst && (
            <Button to={prevPage} rel="prev" size="md">
              ← Previous Page
            </Button>
          )}
          {!isLast && (
            <Button to={nextPage} rel="next" size="md">
              Next Page &rarr;
            </Button>
          )}
        </div>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            author
            date(formatString: "D MMMM YYYY")
            permalink
            title
            topics
          }
        }
      }
    }
    hero: file(relativePath: { eq: "home-hero.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default PostIndex;
